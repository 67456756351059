exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dlaczego-my-js": () => import("./../../../src/pages/dlaczego-my.js" /* webpackChunkName: "component---src-pages-dlaczego-my-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-booking-visit-js": () => import("./../../../src/pages/en/booking-visit.js" /* webpackChunkName: "component---src-pages-en-booking-visit-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-meet-us-js": () => import("./../../../src/pages/en/meet-us.js" /* webpackChunkName: "component---src-pages-en-meet-us-js" */),
  "component---src-pages-en-message-sent-js": () => import("./../../../src/pages/en/message-sent.js" /* webpackChunkName: "component---src-pages-en-message-sent-js" */),
  "component---src-pages-en-offer-js": () => import("./../../../src/pages/en/offer.js" /* webpackChunkName: "component---src-pages-en-offer-js" */),
  "component---src-pages-en-opinions-js": () => import("./../../../src/pages/en/opinions.js" /* webpackChunkName: "component---src-pages-en-opinions-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-why-us-js": () => import("./../../../src/pages/en/why-us.js" /* webpackChunkName: "component---src-pages-en-why-us-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-opinie-js": () => import("./../../../src/pages/opinie.js" /* webpackChunkName: "component---src-pages-opinie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-poznaj-nas-js": () => import("./../../../src/pages/poznaj-nas.js" /* webpackChunkName: "component---src-pages-poznaj-nas-js" */),
  "component---src-pages-rezerwacja-wizyty-js": () => import("./../../../src/pages/rezerwacja-wizyty.js" /* webpackChunkName: "component---src-pages-rezerwacja-wizyty-js" */),
  "component---src-pages-wiadomosc-wyslana-js": () => import("./../../../src/pages/wiadomosc-wyslana.js" /* webpackChunkName: "component---src-pages-wiadomosc-wyslana-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-opinion-js": () => import("./../../../src/templates/opinion.js" /* webpackChunkName: "component---src-templates-opinion-js" */),
  "component---src-templates-suboffer-js": () => import("./../../../src/templates/suboffer.js" /* webpackChunkName: "component---src-templates-suboffer-js" */)
}

