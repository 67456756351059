import React from "react";

import { Logo, Navigation } from "./components";

import "./styles.scss";

function Header({ isOffScreen }) {
  return (
    <header className={isOffScreen ? "header header--isOffScreen" : "header"}>
      <div className="container header__container">
        <Logo zoomOut={isOffScreen} />
        <Navigation />
      </div>
    </header>
  );
}

export default Header;
