import "./styles.scss";

import React from "react";

function SocialButton({ icon, links }) {
  return (
    <div className="social-button">
      <div className="social-button__icon">{icon}</div>
      <div className="social-button__popup">
        <ul>
          {links.map((item, index) => (
            <li className="social-button__link" key={index}>
              <a href={item.href} target="_blank" rel="noreferrer">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SocialButton;
