import "./styles.scss";

import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import { useLocale } from "@context/locale";

import NAVIGATION from "@constants/pl/navigation";
import NAVIGATION_EN from "@constants/en/navigation";

import BooksyLogo from "@icons/booksy.svg";
import FacebookIcon from "@icons/facebook.svg";
import InstagramIcon from "@icons/instagram.svg";
import ArrowIcon from "@icons/arrow.svg";

function Footer() {
  const { t, lang } = useLocale();
  const navigation_list = lang === "en" ? NAVIGATION_EN : NAVIGATION;
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { title: { eq: "Kontakt" } }) {
        nodes {
          pageContact {
            contactAddress
            contactHours
            contactMail
            contactPhone {
              phonenumber
            }
            contactBooksy
            contactFacebook {
              facebook {
                facebookLink
                facebookName
              }
            }
            contactInstagram {
              instagram {
                instagramName
                instagramLink
              }
            }
          }
        }
      }
    }
  `);
  const {
    contactAddress,
    contactHours,
    contactMail,
    contactPhone,
    contactBooksy,
    contactFacebook,
    contactInstagram,
  } = data.allWpPage.nodes[0].pageContact;

  return (
    <footer className="footer">
      <div className="footer__container container">
        <a href="#top" className="footer__arrow">
          <ArrowIcon width={34} height={26} />
        </a>
        <div className="footer__main">
          <div className="footer__contact">
            <div dangerouslySetInnerHTML={{ __html: contactAddress }} />
            <br />
            <h5>{t("Godziny otwarcia")}:</h5>
            <div dangerouslySetInnerHTML={{ __html: contactHours }} />
            <br />
            <a href={`mailto:${contactMail}`}>{contactMail}</a>
          </div>
          <nav className="footer__navigation">
            {navigation_list.map((item) => (
              <Link
                to={item.href}
                className="footer__navigation-link"
                activeClassName="footer__navigation-link--active"
                key={item.href}
              >
                {item.name}
              </Link>
            ))}
          </nav>
        </div>

        <div className="footer__reservation">
          <h5>{t("Zarezerwuj wizytę")}</h5>
          <div className="footer__reservation-booksy">
            <h6>{t("Z użyciem aplikacji")}</h6>
            <a href={contactBooksy} target="_blank" rel="noopener noreferrer">
              <BooksyLogo width={198} height={45} className="footer__booksy" />
            </a>
          </div>
          <div className="footer__reservation-phone">
            <h6>{t("Zadzwoń do nas")}</h6>
            <a href={`tel:${contactPhone[0].phonenumber}`}>
              {contactPhone[0].phonenumber}
            </a>
          </div>
        </div>

        <div className="footer__socials">
          <h5>{t("Social media")}</h5>
          <p>
            <FacebookIcon width={10} height={20} className="icon" />
            {contactFacebook.map((item) => (
              <a
                href={item.facebook.facebookLink}
                target="_blank"
                rel="noreferrer"
                key={item.facebook.facebookLink}
              >
                {item.facebook.facebookName}
              </a>
            ))}
          </p>
          <p href="http://instagram.com" target="_blank">
            <InstagramIcon width={20} height={20} className="icon" />
            {contactInstagram.map((item) => (
              <a
                href={item.instagram.instagramLink}
                target="_blank"
                rel="noreferrer"
                key={item.instagram.instagramLink}
              >
                {item.instagram.instagramName}
              </a>
            ))}
          </p>
        </div>

        <div className="footer__bottom">
          <p>Copyright © 2022 Dream Factory</p>
          <Link to={t("privacy-policy_link")}>{t("Polityka prywatności")}</Link>
          <p>
            {t("Projekt i wykonanie")}: &nbsp;
            <a
              href="http://adream.pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              ADream
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
