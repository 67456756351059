import React from "react";

import "./styles.scss";

function Hamburger({ active, onClick }) {
  return (
    <button
      className={active ? `hamburger hamburger--active` : `hamburger`}
      onClick={onClick}
      type="button"
    >
      <span />
    </button>
  );
}

export default Hamburger;
