import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import { useLocale } from "@context/locale";

import LogoImage from "@icons/logo.svg";

function Logo({ zoomOut }) {
  const { t } = useLocale();

  return (
    <Link
      to={t("index_link")}
      className={zoomOut ? "logo logo--zoomOut" : "logo"}
    >
      <LogoImage />
    </Link>
  );
}

export default Logo;
