import React from "react";
import LocaleProvider from "@context/locale";
import { CookiesProvider } from "react-cookie";
import loadable from "@loadable/component";

import { Menu, Footer } from "./components";

import "./styles.scss";

const CookiesPopup = loadable(() => import("./components/CookiesPopup"));

function Layout({ location, translations = [], children }) {
  return (
    <div className="layout">
      <LocaleProvider translations={translations} location={location}>
        <CookiesProvider>
          <div id="top" />
          <Menu />
          <CookiesPopup />
          {children}
          <Footer />
        </CookiesProvider>
      </LocaleProvider>
    </div>
  );
}

export default Layout;
