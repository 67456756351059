import React from "react";

import { Link } from "gatsby";
import { useLocale } from "@context/locale";

import NAVIGATION from "@constants/pl/navigation";
import NAVIGATION_EN from "@constants/en/navigation";

import "./styles.scss";

function Navigation() {
  const { lang } = useLocale();
  const navigation_list = lang === "en" ? NAVIGATION_EN : NAVIGATION;

  return (
    <nav className="navigation">
      {navigation_list.map((item) => (
        <Link
          to={item.href}
          className="navigation__link"
          activeClassName="navigation__link--active"
          key={item.href}
        >
          {item.name}
        </Link>
      ))}
    </nav>
  );
}

export default Navigation;
