import "./styles.scss";

import React from "react";
import { useCookies } from "react-cookie";

function CookiesPopup() {
  const [{ cookie_accepted }, setCookie] = useCookies(["cookie_accepted"]);

  if (cookie_accepted) {
    return null;
  }
  return (
    <div
      className={
        cookie_accepted
          ? "cookies-popup cookies-popup--hidden"
          : "cookies-popup"
      }
    >
      <p>
        Aby zoptymalizować naszą stronę internetową i stale ją ulepszać, używamy
        plików cookies.
      </p>
      <button
        className="cookies-popup__button"
        onClick={() => setCookie("cookie_accepted", "true")}
        type="button"
      >
        Akceptuję
      </button>
    </div>
  );
}

export default CookiesPopup;
