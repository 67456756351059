const NAVIGATION = [
  {
    name: "Meet us",
    href: "/en/meet-us/",
  },
  {
    name: "Offer and price",
    href: "/en/offer/",
  },
  {
    name: "Why us?",
    href: "/en/why-us/",
  },
  {
    name: "Gallery",
    href: "/en/gallery/",
  },
  {
    name: "Opinions",
    href: "/en/opinions/",
  },
  {
    name: "Blog",
    href: "/en/blog/",
  },
  {
    name: "Contact",
    href: "/en/contact/",
  },
];

export default NAVIGATION;
