import "./styles.scss";

import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import { useLocale } from "@context/locale";

import NAVIGATION from "@constants/pl/navigation";

import BooksyLogo from "@icons/booksy.svg";

function MobileMenu({ isOpen = false }) {
  const { t } = useLocale();
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { title: { eq: "Kontakt" } }) {
        nodes {
          pageContact {
            contactPhone {
              phonenumber
            }
            contactBooksy
          }
        }
      }
    }
  `);
  const phone_number =
    data.allWpPage.nodes[0].pageContact.contactPhone[0].phonenumber;
  const booksy_link = data.allWpPage.nodes[0].pageContact.contactBooksy;

  return (
    <div className={isOpen ? `mobile-menu mobile-menu--active` : `mobile-menu`}>
      <nav className="mobile-navigation">
        {NAVIGATION.map((item) => (
          <Link
            to={item.href}
            className="mobile-navigation__link"
            activeClassName="mobile-navigation__link--active"
            key={item.href}
          >
            {item.name}
          </Link>
        ))}
      </nav>
      <p className="mobile-menu__reservation">
        {t("Zarezerwuj wizytę z")}
        <a href={booksy_link} target="_blank" rel="noreferrer">
          <BooksyLogo className="mobile-menu__booksy" />
        </a>
        <br />
        {t("lub zadzwoń")} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;
        <a href={`tel:${phone_number}`}>{phone_number}</a>
      </p>
    </div>
  );
}

export default MobileMenu;
