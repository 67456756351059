const NAVIGATION = [
  {
    name: "Poznaj nas",
    href: "/poznaj-nas/",
  },
  {
    name: "Oferta i cennik",
    href: "/oferta/",
  },
  {
    name: "Dlaczego my?",
    href: "/dlaczego-my/",
  },
  {
    name: "Galeria",
    href: "/galeria/",
  },
  {
    name: "Opinie",
    href: "/opinie/",
  },
  {
    name: "Blog",
    href: "/blog/",
  },
  {
    name: "Kontakt",
    href: "/kontakt/",
  },
];

export default NAVIGATION;
