import "./styles.scss";

import React, { useState, useRef, useEffect } from "react";
import { useScrollDirection } from "react-use-scroll-direction";
import { globalHistory } from "@reach/router";

import { TopBar, Header, MobileMenu } from "./components";

function Menu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuIsOffScreen, setMenuIsOffScreen] = useState(false);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const menu = useRef(null);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // Refresh mobileMenuOpen
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setMobileMenuOpen(false);
    });
  }, [setMobileMenuOpen]);

  // Set hidden top_bar if scroll in up and fixed
  useEffect(() => {
    if (scrollPosition > 180) {
      setMenuIsOffScreen(true);
    } else {
      setMenuIsOffScreen(false);
    }

    if (menuIsOffScreen) {
      menu.current.classList.add("menu--fixed");
    } else {
      menu.current.classList.remove("menu--fixed");
    }
  }, [isScrollingDown, isScrollingUp]);

  // Detect scroll position
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Open / hidden mobileMenu
  useEffect(() => {
    if (mobileMenuOpen) {
      menu.current.classList.add("menu--mobileMenuIsOpen");
    } else {
      menu.current.classList.remove("menu--mobileMenuIsOpen");
    }
  }, [mobileMenuOpen]);

  return (
    <div className="menu" ref={menu}>
      <TopBar
        mobileMenuOpen={mobileMenuOpen}
        hamburgerOnClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      />
      <Header isOffScreen={menuIsOffScreen} />
      <MobileMenu isOpen={mobileMenuOpen} />
    </div>
  );
}

export default Menu;
