import "./styles.scss";

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { useLocale } from "@context/locale";

import BooksyLogo from "@icons/booksy.svg";
import FacebookIcon from "@icons/facebook.svg";
import InstagramIcon from "@icons/instagram.svg";

import { LanguageSwitcher, Hamburger, SocialButton } from "./components";

function TopBar({ mobileMenuOpen, hamburgerOnClick }) {
  const { t } = useLocale();
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { title: { eq: "Kontakt" } }) {
        nodes {
          pageContact {
            contactPhone {
              phonenumber
            }
            contactBooksy
            contactFacebook {
              facebook {
                facebookLink
                facebookName
              }
            }
            contactInstagram {
              instagram {
                instagramName
                instagramLink
              }
            }
          }
        }
      }
    }
  `);
  const { contactBooksy, contactFacebook, contactInstagram, contactPhone } =
    data.allWpPage.nodes[0].pageContact;

  const facebook_data = contactFacebook.map((item) => ({
    name: item.facebook.facebookName,
    href: item.facebook.facebookLink,
  }));
  const instagram_data = contactInstagram.map((item) => ({
    name: item.instagram.instagramName,
    href: item.instagram.instagramLink,
  }));

  return (
    <div className="top-bar">
      <div className="container top-bar__container">
        <p className="top-bar__contact">
          {t("Zarezerwuj wizytę z")}
          <a href={contactBooksy} target="_blank" rel="noreferrer">
            <BooksyLogo className="top-bar__booksy" />
          </a>
          {t("lub zadzwoń")}{" "}
          <a href={`tel:${contactPhone[0].phonenumber}`}>
            {contactPhone[0].phonenumber}
          </a>
        </p>

        <div className="top-bar__socials">
          <SocialButton
            icon={<FacebookIcon width={7} height={14} />}
            links={facebook_data}
          />
          <SocialButton
            icon={<InstagramIcon width={14} height={14} />}
            links={instagram_data}
          />
        </div>
        {/* <LanguageSwitcher /> */}

        <Hamburger
          active={mobileMenuOpen}
          onClick={() => hamburgerOnClick(!mobileMenuOpen)}
        />
      </div>
    </div>
  );
}

export default TopBar;
