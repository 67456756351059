import React, { createContext, useCallback, useContext } from "react";

import pl_translations from "@locales/pl/translation.json";
import en_translations from "@locales/en/translation.json";
import pl_links from "@locales/pl/links.json";
import en_links from "@locales/en/links.json";

const LocaleContext = createContext();

const getLang = (path) => {
  switch (true) {
    case path.startsWith("/en/"):
      return "en";
    default:
      return "pl";
  }
};

function LocaleProvider({ location, children }) {
  const langs = ["en", "pl"];
  const lang = getLang(location.pathname);
  const languages = {
    pl: { ...pl_translations, ...pl_links },
    en: { ...en_translations, ...en_links },
  };

  const t = (key) => languages[lang]?.[key] || key;

  return (
    <LocaleContext.Provider value={useCallback({ langs, lang, t })}>
      {children}
    </LocaleContext.Provider>
  );
}

const useLocale = () => useContext(LocaleContext);
export { useLocale };
export default LocaleProvider;
